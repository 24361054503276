<template>
  <topbar
    :titulo="$t('FINANCEIRO.RELATORIO_ANALISE_RETROATIVA_CONTAS_PAGAR')"
    :subtitulo="
      $t('FINANCEIRO.RELATORIO_ANALISE_RETROATIVA_CONTAS_PAGAR_DESCRICAO')
    "
    :breadcrumbs="breadcrumbs"
  />
</template>

<script>
// Utils & Aux:
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

export default {
  components: { Topbar },
  data() {
    return {
      breadcrumbs: [
        { titulo: this.$t('FINANCEIRO.FINANCEIRO') },
        { titulo: this.$t('FINANCEIRO.RELATORIOS') },
        {
          titulo: this.$t(
            'FINANCEIRO.RELATORIO_ANALISE_RETROATIVA_CONTAS_PAGAR'
          ),
        },
      ],
    };
  },
};
</script>
    